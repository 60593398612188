import React, { useEffect,useState } from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet
    } from 'react-native'
function Timer() {
    const [timer, setTimer] = useState(60);
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);
  return (
    <Text>{timer}</Text>
  )
}

export default Timer